import React from 'react';
import useScript from '../../hooks/useScript';

import './Login.scss';

const Login = () => {
    // <!-- 3rd Party LIBs -->
    LoadScripts();
    return (
        <div className="profile bg-tapete">
            <div className="body1">
                <div className="popUpTerminos popup hidden">
                    <a href="/#" className="close btn-close-popup"/>
                    <span className="title">Términos y condiciones de uso</span>
                    <div className="bloque1">
                        Hemos actualizado los terminos y condiciones de uso de MasterMus desde la ultima vez que accediste, por
                        favor revisa los nuevos, y pulsa aceptar si deseas continuar disfrutando de la plataforma de juego de mus numero 1
                    </div>
                    <div className="bloque2"/>
                    <div className="btns">
                        <button id="btn-cancel-terms" className="btn-terms red btn-close-popup">CANCELAR</button>
                        <button id="btn-accept-terms" className="btn-terms green">ACEPTAR </button>
                    </div>
                </div>
            </div>
            <section className="loginForm">
                <div className="logotipo"> MasterMus </div>
                <div className="boxShadow">
                    <div className="bloq">
                        <a className="facebook" href="/#"><span>Inicia sesión con Facebook</span></a>
                    </div>
                    <div className="bloq">
                        <form method="POST">
                            <div className="textInput">
                                <div>
                                    <label>EMAIL</label>
                                    <input type="text" placeholder="Email" id="input-login"/>
                                    <p className="help-block hidden">Debe escribir su usuario</p>
                                </div>
                                <div>
                                    <label>CONTRASEÑA</label>
                                    <input type="password" placeholder="********" id="input-password"/>
                                    <p className="help-block hidden">Debe escribir su contraseña</p>
                                </div>
                            </div>
                            <a className="enter" href="/#" id="btn-submit">Entrar</a>
                        </form>
                    </div>
                    <div className="bloq">
                        <a className="noAccount" href="recover-pass">He olvidado mi contraseña</a>
                        <a className="noAccount" href="register">No tengo cuenta en <strong>MasterMus</strong>. <u>Quiero registrarme.</u></a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;

function LoadScripts() {
    useScript('static/js/libs/jQuery/jquery.min.js');

    // <!-- CONFIG -->
    useScript('static/js/globals.js');
    // <script type="text/javascript" th:attr="src=${env}" />
    useScript('static/js/config/config-LOCAL.js');
    useScript('static/js/logger.js');

    // <!-- WEBSOCKETS -->
    useScript('static/js/libs/websockets/sockjs.js');
    useScript('static/js/libs/websockets/stomp.js');
    useScript('static/js/api/api_sockets.js');

    useScript('static/js/api/api_commons.js');
    useScript('static/js/api/api_login.js');
    useScript('static/js/libs/facebook.js');
    useScript('static/js/login.js');
}

