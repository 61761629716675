import React from 'react';
import { Switch, Route } from "react-router-ts";
import Home from "../components/home/Home"
import Login from "../components/account/Login"
import RecoverPassword from "../components/account/RecoverPassword"
import Register from "../components/account/Register"
import RegisterSuccess from "../components/account/RegisterSuccess"
import ChangePassword from "../components/account/ChangePassword"

export default class Routes extends React.Component {
    render(): JSX.Element {
      return (
        <Switch>
          <Route path="/" component={Login} />
          <Route path="/home" component={Home} />
          <Route path="/register" component={Register} />
          <Route path="/register-success" component={RegisterSuccess} />
          <Route path="/change-pass" component={ChangePassword} />
          <Route path="/recover-pass" component={RecoverPassword} />
        </Switch>
      );
    }
  }