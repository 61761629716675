import React from 'react';

const Popup = () => {
    return (
        <div>
            {/* <!-- PROFILE > Editar contraseña --> */}
            <div id="popup-pass" className="popup hidden">
                <a href="/#" className="close btn-close-popup" >CERRAR</a>
                <form>
                    <div className="textInput">
                        <div>
                            <label>NUEVA CONTRASEÑA</label>
                            <input type="password" placeholder="*******" id="new-password-1"/>
                            <p className="help-block hidden" style={{color: "red"}}/>
                        </div>
                        <div>
                            <label>REPITA CONTRASEÑA</label>
                            <input type="password" placeholder="*******" id="new-password-2"/>
                            <p className="help-block hidden" style={{color: "red"}}/>
                        </div>
                    </div>
                    <a className="enter" href="/#" id="btn-change-password">Guardar</a>
                </form>
            </div>

            {/* <!-- PREPARTIDAS > POPUP INVITAR --> */}
            <div id="popup-invitar" className="popup floatContainer  hidden " >
                {/* <!-- BTN CERRAR --> */}
                <a href="/#" className="close  btn-close-popup">CERRAR</a>
                {/* <!-- COL IZDA --> */}
                <div className="left">
                    <span className="title">¿A quien quieres invitar?</span>
                    <div className="tabsInv">
                        <a href="/#"  id="btn-inv-tab-fav"   className="selected left tab">Favoritos</a>
                        <a href="/#"  id="btn-inv-tab-todos" className="right">Todos</a>
                    </div>
                    <ul id="list-inv-favoritos"/>
                    <ul id="list-inv-todos" className="hidden"/>
                </div>
                {/* <!-- COL DCHA --> */}
                <div className="right">
                    {/* <!-- Detalle avatar seleccionado --> */}
                    <div className="avatar">
                        <img id="favorite-image" src="static/img/icon/profile.png" width="150" height="150" alt="" className="conected" />
                    </div>
                    {/* <!-- Detalle user seleccionado --> */}
                    <div className="name">
                        <img src="static/img/icon/flecha_up.png" width="14" height="12" alt="Flecha Izda"/>
                        <span id="favorite-name"></span>
                    </div>
                    {/* <!-- BOTONES --> */}
                    <div className="buttons">
                        <button className="seleccionar" id="btn-invite" data-room="" data-username="" data-position="">INVITAR</button>
                        <button className="cancelar btn-close-popup">CANCELAR</button>
                    </div>
                </div>
            </div>

            {/* <!-- PREPARTIDAS > Seleccion 8 Reyes --> */}
            <div id="popup-kings" className="popup floatContainer-multi hidden"  >
                <a href="/#" className="close btn-close-popup">CERRAR</a>
                <ul>
                    {/* <!-- 8 Reyes Mode = true --> */}
                    <li><a href="/#" className="list-reyes" data-value="false">4 REYES</a></li>
                    <li><a href="/#" className="list-reyes" data-value="true" >8 REYES</a></li>
                </ul>
                <a className="enter btn-close-popup" href="/#">Guardar</a>
            </div>

            {/* <!-- PREPARTIDAS > Seleccion Chicos --> */}
            <div id="popup-chicos" className="popup floatContainer-multi hidden" >
                <a href="/#" className="close btn-close-popup">CERRAR</a>
                <ul>
                    <li><a href="/#" className="list-chicos" data-value="1">1 CHICO</a></li>
                    <li><a href="/#" className="list-chicos" data-value="3">3 CHICOS</a></li>
                    <li><a href="/#" className="list-chicos" data-value="5">5 CHICOS</a></li>
                </ul>
                <a className="enter btn-close-popup" href="/#">Guardar</a>
            </div>

            {/* <!-- PREPARTIDAS > Seleccion Velocidad --> */}
            <div id="popup-speed" className="popup floatContainer-multi hidden popup" >
                <a href="/#" className="close btn-close-popup">CERRAR</a>
                <ul>
                    {/* <!-- Fast speed = true --> */}
                    <li><a href="/#" className="list-speed" data-value="false" data-amount="NORMAL">NORMAL</a></li>
                    <li><a href="/#" className="list-speed" data-value="true"  data-amount="RAPIDO">RÁPIDO</a></li>
                </ul>
                <a className="enter btn-close-popup" href="/#">Guardar</a>
            </div>

            {/* <!-- MENSAJES > Nueva conversacion --> */}
            <div id="popup-chat" className="popup floatContainer hidden" >
                <a href="/#" className="close btn-close-popup"/>
                <div className="left">
                    <span className="title">¿Con quién quieres hablar?</span>
                    {/* <!-- PESTAÑAS (FAVS/TODOS) --> */}
                    <div className="tabsInv">
                        <a href="/#"  id="btn-tab-fav"   className="selected left tab">Favoritos</a>
                        <a href="/#"  id="btn-tab-todos" className="right">Todos</a>
                    </div>
                    {/* <!-- Listados de Usuarios --> */}
                    <ul id="list-favorites-chat"></ul>
                    <ul id="list-users-chat" className="hidden"></ul>
                </div>

                <div className="right">
                    <div className="avatar">
                        <img src="static/img/icon/profile.png"  width="150" height="150" alt="Icono Computer" className="conected" id="favorite-image-chat"/>
                    </div>
                    <div className="name">
                        <img src="static/img/icon/flecha_up.png" width="14" height="12" alt="Flecha Izda"/>
                        <span id="favorite-name-chat"></span>
                    </div>
                    <div className="buttons">
                        <button className="seleccionar" id="btn-chat-new" >HABLAR</button>
                        <button className="cancelar btn-close-popup">CANCELAR</button>
                    </div>
                </div>
            </div>

            {/* <!-- Invitar a amigo --> */}
            <div id="popup-invita-amigo" className="floatContainer floatContainerTournament codigo invitar-amigo popup hidden">
                <a href="/#" className="close btn-close-popup"/>
                <div className="left">
                    <span className="title">Invita a un amigo</span>
                    <p>Escribe el email de tu amigo para enviarle la invitación</p>
                    <input id="input-email-amigo" type="text" placeholder="Email" />
                </div>
                <div className="right">
                    <div className="buttons">
                        <a href="/#"> <button className="seleccionar" id="btn-invitar-amigo">Enviar invitación</button>  </a>
                    </div>
                </div>
            </div>

            {/* <!-- POP UP - Tomar foto con la camara --> */}
            <div id="popup-foto" className="floatContainer hidden popup" >
                <a href="/#" className="close btn-close-popup"/>
                <video id="video"></video>
                <canvas id="canvas" className="hidden"></canvas>
                <img src="" id="photo" alt="foto"/>
                <button id="startbutton">Hacer foto</button>
            </div>

            {/* <!-- ========= POPUP TORNEOS ==========--> */}
            {/* <!-- PREMIOS --> */}
            <div id="popup-tour-prizes" className="floatContainerFases hidden popup">
                <a href="/#" className="close btn-close-popup"/>
                <span className="title">PREMIOS DEL TORNEO</span>
                <div className="prizes">
                    <p> Premio por Fase:            <span id="prize-per-phase" className="creditsLabel">---c</span></p>
                    <p> Premio por ganar el torneo: <span id="prize-total"     className="creditsLabel">---c</span></p>
                </div>
            </div>

            {/* <!-- REGLAS --> */}
            <div id="popup-tour-rules" className="floatContainerFases hidden popup">
                <a href="/#" className="close btn-close-popup"/>
                <span className="title">REGLAS DEL TORNEO</span>
                <div className="rules"> </div>
            </div>

            {/* <!-- CODIGO PROMOCIONAL --> */}
            <div id="popup-tour-code" className="floatContainer codigo floatContainerTournament popup hidden" >
                <a href="/#" className="close btn-close-popup"></a>
                <div className="left">
                    <span className="title">Código promocional</span>
                    <p>Si tienes un código para el torneo introducelo</p>
                    <input type="text" placeholder="Código" id="input-code"/>
                </div>
                <div className="right">
                    <div className="buttons">  <button className="seleccionar" id="btn-tour-code">ACEPTAR</button>   </div>
                </div>
            </div>

            {/* <!-- TOUR Inscripcion > POPUP INVITAR --> */}
            <div id="popup-tour-inscribe" className="popup floatContainer  hidden " >

                {/* <!-- BTN CERRAR --> */}
                <a href="/#" className="close  btn-close-popup">CERRAR</a>

                {/* <!-- COL IZDA --> */}
                <div className="left">
                    <span className="title">¿A quien quieres invitar?</span>
                    <div className="tabsInv">
                        <a href="/#" className="btn-inv-tab-fav selected left tab">Favoritos</a>
                        <a href="/#" className="btn-inv-tab-todos right">Todos</a>
                    </div>
                    <ul className="list-inv-favoritos"/>
                    <ul className="list-inv-todos hidden"/>
                </div>

                {/* <!-- COL DCHA --> */}
                <div className="right">
                    {/* <!-- Detalle avatar seleccionado --> */}
                    <div className="avatar">
                        <img className="favorite-image conected" src="static/img/icon/profile.png" width="150" height="150" alt="" />
                    </div>
                    {/* <!-- Detalle user seleccionado --> */}
                    <div className="name">
                        <img src="static/img/icon/flecha_up.png" width="14" height="12" alt="Flecha Izda"/>
                        <span className="favorite-name"></span>
                    </div>
                    {/* <!-- BOTONES --> */}
                    <div className="buttons">
                        <button className="seleccionar" id="btn-tour-invite" data-tour="0">INVITAR</button>
                        <button className="cancelar btn-close-popup">CANCELAR</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Popup;