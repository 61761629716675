import React, { useEffect, useState, useReducer, useContext } from 'react';
import { Container } from 'react-bootstrap';
import loagingImage  from '../../static/img/other/loading.gif';
import Menu from '../menu/Menu';
import HomeSection from '../sections/HomeSection';
import PlayZone from '../game/PlayZone';
import Popup from '../sections/Popup';
import ProfileSection from '../sections/ProfileSection';
import GameSection from '../sections/GameSection';
import TournamentSection from '../sections/TournamentSection';
import RankingSection from '../sections/RankingSection';
import MessagesSection from '../sections/MessagesSection';
import HelpSection from '../sections/HelpSection';
import useScript from '../../hooks/useScript';

import './Home.scss';
import '../../static/css/styles.scss'

const Home = () => {
    LoadScripts();
    return (
        <div className="home view">
            <Container>
                <div>
                    <div id="modal-loading" className="loading-home spinner-center-loading">
                        <img src={loagingImage}/>
                    </div>
                </div>
                <PlayZone />
                <div className="body">
                    <Menu />
                    <HomeSection />
                    <Popup />
                    <ProfileSection />
                    <GameSection />
                    <TournamentSection />
                    <RankingSection />
                    <MessagesSection />
                    <HelpSection />
                </div>
            </Container >
        </div>
    );
}

export default Home;

function LoadScripts() {
    // <!-- LIBRERIAS -->
    useScript('static/js/libs/jQuery/jquery.min.js');
    useScript('https://code.jquery.com/ui/1.12.1/jquery-ui.js');
    useScript('static/js/libs/jQuery/jquery.colorbox-min.js');
    // <!-- WEBSOCKETS -->
    useScript('static/js/libs/websockets/sockjs.js');
    useScript('static/js/libs/websockets/stomp.js');

    // <!-- CONFIG -->
    useScript('static/js/globals.js');
    // <script type="text/javascript" th:attr="src=${env}" />
    useScript('static/js/config/config-LOCAL.js');
    useScript('static/js/logger.js');
    useScript('static/js/mocks.js');

    // <!-- WEBSOCKETS -->
    useScript('static/js/libs/websockets/sockjs.js');
    useScript('static/js/libs/websockets/stomp.js');

    useScript('static/js/api/api_login.js');
    //useScript('static/js/facebook.js');
    useScript('static/js/login.js');

    // <!-- Utils -->
    useScript('static/js/utils/dateUtils.js');
    useScript('static/js/utils/utils.js');

    // <!-- MODELs -->
    useScript('static/js/model/user.js');
    useScript('static/js/model/roomResult.js');
    useScript('static/js/model/character.js');
    useScript('static/js/model/stage.js');
    useScript('static/js/model/roomWs.js');
    useScript('static/js/model/gameWs.js');

    // <!-- API calls -->
    useScript('static/js/api/api_commons.js');
    useScript('static/js/api/api_sockets.js');

    useScript('static/js/home/purchase.js');
    useScript('static/js/home/profile.js');
    useScript('static/js/home/waiting-room.js');
    useScript('static/js/home/game.js');
    useScript('static/js/home/tournament.js');
    useScript('static/js/home/playzone.js');
    useScript('static/js/home/historic.js');
    useScript('static/js/home/messages.js');
    useScript('static/js/home/ranking.js');
    useScript('static/js/home.js');

    // <!-- for tournament-fases -->
    // <!--<script src="https://unpkg.com/vue/dist/vue.js');-->
    // <!-- DEVELOP VUE -->
    // <!--<script src="https://cdn.jsdelivr.net/npm/vue@2.5.21/dist/vue.min.js');-->
    // <!-- PROD VUE-->
    useScript('https://cdn.jsdelivr.net/npm/vue@2.5.21/dist/vue.min.js');
    useScript('https://unpkg.com/element-ui/lib/index.js');
}
