import React from 'react';

const RankingSection = () => {
    return (
        <section id="section-ranking" className="section" style={{display: "none"}} >
            {/* <!-- PESTAÑAS (N/A) --> */}
            <div className="submenu">
              <ul>
                <li className="first"></li>
                <li className="current"><a href="/#" id="btn-tab-ranking" className="btn-submenu" data-link="ranking">General</a></li>
                <li> <a href="/#" id="btn-tab-friendsRanking" className="btn-submenu" data-link="ranking-fav">Favoritos</a></li>
                <li className="last"></li>
              </ul>
            </div>
            {/* <!-- RANKING FAVORITOS --> */}
            <section id="ranking" className="subsection bg-white section-default" style={{display: "none"}}>
                {/* <!-- CABECERAS --> */}
                <div className="headerBar">
                    <table>
                        <thead>
                            <tr>
                                <td className="pos"><img src="static/img/icon/sello-ranking.png" width="25" height="31" alt="Sello Ranking"/></td>
                                <td className="user">Usuario</td>
                                <td className="star"></td>
                                <td className="win"><span>Puntuacion</span> <span></span></td>
                                <td className="tou1"><span>%</span> <span>Ganadas</span></td>
                                <td className="tou2"><span>Partidas</span> <span>Ganadas</span></td>
                                <td className="tou3"><span>Partidas</span> <span>Perdidas</span></td>
                            </tr>
                        </thead>
                    </table>
                </div>
                {/* <!-- CONTENIDO --> */}
                <div id="div-ranking" className="ranking">
                    <div >
                    <table id="ranking-table">
                        <tbody></tbody>
                    </table>
                    </div>
                </div>
            </section>
            {/* <!-- RANKING FAVs --> */}
            <section id="ranking-fav"    className="subsection bg-white section-default" style={{display: "none"}} >
                {/* <!-- CABECERAS --> */}
                <div className="headerBar">
                    <table>
                        <thead>
                        <tr>
                            <td className="pos"><img src="static/img/icon/sello-ranking.png" width="25" height="31" alt="Sello Ranking"/></td>
                            <td className="user">Usuario</td>
                            <td className="star"></td>
                            <td className="win"><span>Puntuacion</span> <span></span></td>
                            <td className="tou1"><span>%</span> <span>Ganadas</span></td>
                            <td className="tou2"><span>Partidas</span> <span>Ganadas</span></td>
                            <td className="tou3"><span>Partidas</span> <span>Perdidas</span></td>
                        </tr>
                        </thead>
                    </table>
                </div>
                {/* <!-- CONTENIDO --> */}
                <div id="div-ranking-fav" className="ranking">
                    {/* <!--ESTE ID ANTES ERA solo ranking --> */}
                    <div >
                        <table id="ranking-table-fav">
                            <tbody></tbody>
                        </table>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default RankingSection;
