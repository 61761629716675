import React from 'react';

const HelpSection = () => {
    return (
        <div>
            <section id="section-help" className="section" style={{display: "none"}}>

                <div className="submenu">   </div>

                <section id="help" className="subsection bg-white section-default" style={{display: "none"}}>

                    <div className="ayuda">
                        <span className="title">Ayuda</span>
                        <div className="textContent">
                            {/* <!--<iframe src="http://www.zonaapp.es/" style="width: 100%; height: 100%;"> </iframe>--> */}
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default HelpSection;