import React from 'react';
import Routes from './routes/routes';
import './App.css';

const App = () => {
  return (
    <div className="layer">
      <Routes/>
    </div>
  );
}

export default App;
