import React from 'react';

const PlayZone = () => {
    return (
        <div className="bodyPlay playzone hidden">
            {/* <!-- MODAL CONFIGURACION  --> */}
            <div className="configPop hidden popup">
                <a className="close btn-close-popup"/>
                <span className="title">Configuración</span>
                <div className="options">
                    <div className="line">
                        <button id="btn-config-sound" className="active btn-config" ></button>
                        <span>Sonido</span>
                    </div>
                    <div>
                        <button id="btn-config-chat" className="active btn-config" ></button>
                        <span>Chat</span>
                    </div>
                </div>
            </div>

            {/* <!-- CHAT --> */}
            <div className="floatContainerFases playChat popup hidden">
                <a className="close btn-close-popup"/>
                <span className="title">CHAT</span>
                <div className="chatZone2">
                    <ul id="chat-opened-ul"> </ul>
                </div>
                <textarea id="input-chat-text" className="text" style={{resize:"none"}} placeholder="Escribe aquí tu comentario..."></textarea>
            </div>

            {/* <!-- MENU FIN PARTIDA --> */}
            <div className="finPartida popup hidden">
                <span className="title">Fin de la partida</span>
                <p>Los ganadores de la partida han sido:</p>
                <p className="players">Jugador 1 &amp; Jugador 2</p>
                <div className="actions">
                    <button id="btn-share"                  >COMPARTIR RESULTADO</button>
                    <button id="btn-exit-playzone"          >MENÚ PRINCIPAL</button>
                    <button id="btn-reportar-comportamiento">REPORTAR COMPORTAMIENTO</button>
                </div>
                <img src="static/img/logo/Logo_mastermus_grises.png" width="258" height="69" alt="Logo MasterMus Grises"/>
            </div>

            {/* <!-- (FIN PARTIDA) >  Comparte tu partida (al terminar partida y pulsar btn) --> */}
            <div className="sabiasQue comparte popup hidden" style={{zIndex: 13}}>
                <a className="close btn-close-comparte"/>
                <span className="title">Comparte tu partida</span>
                <div className="social">
                    <button className="facebook" id="btn-facebook">
                        <img src="static/img/icon/facebook.png" width="111" height="109" alt="Facebook"/>
                    </button>
                    <button id="btn-twitter" className="twitter">
                        <img src="static/img/icon/twitter.png" width="111" height="109" alt="Twitter"/>
                    </button>
                </div>
                <img src="static/img/logo/Logo_mastermus_grises.png" width="258" height="69" alt="Logo MasterMus Grises"/>
            </div>

            {/* <!-- (FIN PARTIDA) > REPORTAR a un jugador (B-MULTI-TORNEO) (al terminar) --> */}
            <div className="reportPop popup hidden">
                <a className="close btn-close-reporta"/>
                <div className="bloc">
                    <div className="users">
                        <button className="active report-option" id="report-user1" data-id=""/>
                        <div className="line"/>
                        <button className="report-option" id="report-user2" data-id=""/>
                        <div className="line"/>
                        <button className="report-option" id="report-user3" data-id=""/>
                    </div>
                    <textarea id="report-concept"></textarea>
                    <div className="actions">
                        <button className="aceptar"  id="btn-reportar"  data-idRoom="">Reportar</button>
                        <button className="cancelar btn-close-reporta" >Cancelar</button>
                    </div>
                    <div className="phrase hidden">
                        <p><span>GRACIAS</span> por tu mensaje, nos pondremos en contacto contigo.</p>
                    </div>
                </div>
            </div>

            {/* <!-- RESUMEN RONDA: resultados + cartas --> */}
            <div className="resuPartida hidden">
                <div className="izq">
                    <img id="resumen-cl0" src="static/img/card/1oro.png"      width="152" height="238"/>
                    <img id="resumen-cl1" src="static/img/card/1oro.png"  width="152" height="238"/>
                    <img id="resumen-cl2" src="static/img/card/1oro.png"   width="152" height="238"/>
                    <img id="resumen-cl3" src="static/img/card/1oro.png" width="152" height="238"/>
                </div>
                <div className="cen">
                    <div className="top">
                        <img id="resumen-ct0" src="static/img/card/1oro.png"      width="152" height="238"/>
                        <img id="resumen-ct1" src="static/img/card/1oro.png"  width="152" height="238"/>
                        <img id="resumen-ct2" src="static/img/card/1oro.png"   width="152" height="238"/>
                        <img id="resumen-ct3" src="static/img/card/1oro.png" width="152" height="238"/>
                    </div>
                    <div className="mid">
                        <div className="head">
                            <div className="pareja1">
                                <span className="sun">&amp;</span>
                                <div className="conj">
                                    <span className="first"></span>
                                    <span className="second"></span>
                                </div>
                            </div>
                            <div className="pareja2">
                                <span className="sun">&amp;</span>
                                <div className="conj">
                                    <span className="first"></span>
                                    <span className="second"></span>
                                </div>
                            </div>
                        </div>
                        <div className="table">
                            <div className="linea impar resul-grande">
                                <div className="titulo">GRANDE</div>
                                <div className="num1">0</div>
                                <div className="num2">0</div>
                            </div>
                            <div className="linea  resul-chicas">
                                <div className="titulo">PEQUEÑA</div>
                                <div className="num1">0</div>
                                <div className="num2">0</div>
                            </div>
                            <div className="linea impar  resul-pares">
                                <div className="titulo">PARES</div>
                                <div className="num1">0</div>
                                <div className="num2">0</div>
                            </div>
                            <div className="linea  resul-juego">
                                <div className="titulo">JUEGO</div>
                                <div className="num1">0</div>
                                <div className="num2">0</div>
                            </div>
                            <div className="linea impar  resul-punto">
                                <div className="titulo">PUNTO</div>
                                <div className="num1">0</div>
                                <div className="num2">0</div>
                            </div>
                        </div>
                        <div className="foot resul-mano">
                            <div className="titulo">MANO</div>
                            <div className="num1">0</div>
                            <div className="num2">0</div>
                        </div>
                    </div>
                    <div className="bot">
                        <img id="resumen-cb0" src="static/img/card/1oro.png" width="152" height="238"/>
                        <img id="resumen-cb1" src="static/img/card/1oro.png" width="152" height="238"/>
                        <img id="resumen-cb2" src="static/img/card/1oro.png" width="152" height="238"/>
                        <img id="resumen-cb3" src="static/img/card/1oro.png" width="152" height="238"/>
                    </div>
                </div>
                <div className="der">
                    <img id="resumen-cr0" src="static/img/card/1oro.png" width="152" height="238"/>
                    <img id="resumen-cr1" src="static/img/card/1oro.png" width="152" height="238"/>
                    <img id="resumen-cr2" src="static/img/card/1oro.png" width="152" height="238"/>
                    <img id="resumen-cr3" src="static/img/card/1oro.png" width="152" height="238"/>
                </div>
            </div>

            {/* <!-- Descartes MUS --> */}
            <div className="descartes hidden">
                <span className="title">INDICA TUS DESCARTES</span>
                <img id="descarte0" src="static/img/other/destacado_descarte.png" width="106" height="157" alt="Destacado Descarte" className="visibility"/>
                <img id="descarte1" src="static/img/other/destacado_descarte.png" width="106" height="157" alt="Destacado Descarte" className="visibility"/>
                <img id="descarte2" src="static/img/other/destacado_descarte.png" width="106" height="157" alt="Destacado Descarte" className="visibility"/>
                <img id="descarte3" src="static/img/other/destacado_descarte.png" width="106" height="157" alt="Destacado Descarte" className="visibility"/>
            </div>

            {/* <!-- BOCADILLOS PERSONAJES --> */}
            <div className="bocadillos">
                <div className="bocadillo bocaRight  hidden" id="bocadillo-right"/>
                <div className="bocadillo bocaCenter hidden" id="bocadillo-top"/>
                <div className="bocadillo bocaLeft   hidden" id="bocadillo-left"/>
                <div className="bocadillo bocaBottom hidden" id="bocadillo-bottom"/>
            </div>

            {/* <!-- MESA DE JUEGO --> */}
            <div className="mesaDeJuego">
                <img src="" width="1920" height="1080" alt="Bg Fournier" />
            </div>

            <section className="scene">
                <section className="header">
                    <div className="logo">
                        <img src="static/img/logo/logotipo.png" width="212" height="70" alt="Logotipo"/>
                    </div>
                    <div className="team1">
                        <img className="charbg" src="static/img/other/char-bg.png" width="53" height="50" alt="Char Bg"/>
                        <div className="char">C</div>
                        <img className="countbg1" src="static/img/backgrounds/bg-count1.png" width="104" height="40" alt="Bg Count1"/>
                        <div className="count"><span id="vacas-equipo1">0</span>-<span id="vacas-equipo2">0</span></div>
                    </div>
                    <div className="team2">
                        <img className="charbg" src="static/img/other/char-bg.png" width="53" height="50" alt="Char Bg"/>
                        <div className="char">P</div>
                        <img className="countbg2" src="static/img/backgrounds/bg-count1.png" width="130" height="40" alt="Bg Count2"/>
                        <div className="count">
                            <span id="puntos-equipo1">0</span>-<span id="puntos-equipo2">0</span>
                        </div>
                    </div>
                    <div className="" id="fase" style={{background: "url('static/img/backgrounds/bg-hand.png')", float: "left", color: "#FFFFFF", padding: "5px 17px", fontSize: "21px", marginTop: "7px", marginLeft: "635px", position:"absolute"}}>
                        CARGANDO...
                    </div>
                    <a id="btn-config"    className="config" href="#" />
                    <a id="btn-exit-game" className="exit-game" />
                </section>

                <section className="subHeader">
                    {/* <!-- LEFT PLAYER (1) --> */}
                    <div id="player-left" className="player1 bg">
                        <img className="playerBg"      src="static/img/backgrounds/bg-player-name.png" width="180" height="41" alt="Bg Player Name"/>
                        <img className="mano mano-img" src="static/img/other/mano-empty.png"     width="50"  height="55" alt="Icoguy"/>
                        <img className="iconGuy"       src="static/img/icon/icoguy.png"         width="44"  height="44" alt="Icoguy" id="LEFT-player-sign" />
                        <span id="left-player-name"></span>
                    </div>
                    {/* <!-- TOP PLAYER (2) --> */}
                    <div id="player-top" className="player2 bg">
                        <img className="playerBg"       src="static/img/backgrounds/bg-player-name.png" width="180" height="41" alt="Bg Player Name"/>
                        <img className="mano mano-img"  src="static/img/other/mano-empty.png"     width="50"  height="55" alt="Icoguy"/>
                        <img className="iconGuy"        src="static/img/icon/icoguy.png"         width="44"  height="44" alt="Icoguy" id="TOP-player-sign" />
                        <span id="top-player-name"></span>
                    </div>
                    {/* <!-- RIGHT PLAYER (3) --> */}
                    <div id="player-right" className="player3 bg">
                        <img className="playerBg"      src="static/img/backgrounds/bg-player-name-red.png" width="180" height="41" alt="Bg Player Name"/>
                        <img className="mano mano-img" src="static/img/other/mano-empty.png"         width="50"  height="55" alt="Icoguy"/>
                        <img className="iconGuy"       src="static/img/icon/icoguy.png"             width="44"  height="44" alt="Icoguy" id="RIGHT-player-sign" />
                        <span id="right-player-name"></span></div>
                </section>

                <section className="subHeadHeader">
                    <div className="player1"/>
                    <div className="player2"/>
                    <div className="player3"/>
                </section>

                <section className="gameZone">
                    <div className="izq"><img src="" width="496" height="620" alt="Viejo Izq"       id="character-left"/></div>
                    <div className="cen"><img src="" width="480" height="310" alt="Chica Cen"       id="character-top"/></div>
                    <div className="der"><img src="" width="480" height="620" alt="Chica Gafas Der" id="character-right"/></div>
                </section>

                <section className="cards sortable">
                    <div className="card1"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA" width="152" height="238" id="carta0" className="card"/></div>
                    <div className="card2"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA" width="152" height="238" id="carta1" className="card"/></div>
                    <div className="card3"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA" width="152" height="238" id="carta2" className="card"/></div>
                    <div className="card4"><img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA" width="152" height="238" id="carta3" className="card"/></div>
                </section>

                <div className="timeLine">
                    <div className="line"></div>
                </div>

                {/* <!-- Chat PLEGADO --> */}
                <section className="chat">
                    <a className="plus" href="#" id="btn-plus">Plus</a>
                    <div className="chatZone chatZone2" >
                        <ul id="chat-closed-ul" style={{overflow:"hidden"}}>
                        </ul>
                        <div className="buttons">
                            <textarea className="text" id="input-chat-text2" style={{resize:"none", overflow:"hidden"}} placeholder="Escribe aquí el texto del chat..."></textarea>
                        </div>
                    </div>
                    <a className="opts btn-chat-send" href=""/>
                </section>

                {/* <!-- BOTTOM PLAYER --> */}
                <section className="gamePad">
                    <div id="player-bottom" className="player">
                        <img className="avatar" src="" width="200" height="200" id="character-bottom"/>
                        <div className="profile">
                            <img  className="bgname" src="static/img/backgrounds/bg-player-name.png" width="200" height="65"  style={{marginLeft: "36px", marginTop: "188px"}} alt="Bg Player Name"/>
                            <span className="mano-bottom" style={{marginLeft: "41px"}} />
                            <span className="stateName" style={{marginLeft: "53px"}} id="bottom-player-name"/>
                        </div>
                    </div>
                </section>

                {/* <!-- BTN Envido Desplegado ( + y -)  --> */}
                <div className="fee envido" style={{display: "none"}} id="div-envido-exp">
                    <a href="#" className="menos" id="btn-envido-menos"/> <span className="contador">2</span> <a href="#" className="mas" id="btn-envido-mas"/>
                    <span className="btn-action fee" id="btn-envido-2" data-action="ENVIDO">ENVIDO</span>
                </div>

                {/* <!-- Controles (PASO-QUIERO-ENVIDO-ORDAGO) --> */}
                <div className="buttons">
                    <div className="grupos-botones hidden" id="grupo1">
                        <button className="fee btn-action" data-action="PASO"   id="btn-paso"  ><span>PASO  </span></button>
                        <button className="fee btn-action" data-action="QUIERO" id="btn-quiero"><span>QUIERO</span></button>
                        <button className="fee btn-action"                      id="btn-envido"><span>ENVIDO</span></button>
                        <button className="fee btn-action" data-action="ORDAGO" id="btn-ordago"><span>ÓRDAGO</span></button>
                    </div>
                    <div className="grupos-botones hidden" id="grupo2">
                        <button className="fee btn-action" data-action="MUS"    id="btn-mus">   <span>MUS   </span></button>
                        <button className="fee btn-action" data-action="NO_MUS" id="btn-no-mus"><span>NO MUS</span></button>
                    </div>
                    <div className="grupos-botones hidden" id="grupo3">
                        <button className="btn-action" data-action="DESCARTE" id="btn-descartar"><span style={{fontSize: "12px"}}>DESCARTAR</span>
                        </button>
                    </div>
                </div>

                {/* <!-- SEÑAS --> */}
                <div className="buttons-opt">
                    <a className="button-opt right-dotted sign" href="#" data-id="CIEGO" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/CIEGO.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>Nada</span>
                    </a>
                    <a className="button-opt sign" href="#" data-id="DOS_CERDOS" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/DOS_CERDOS.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>Dos reyes</span>
                    </a>
                    <a className="button-opt sign" href="#" data-id="DOS_PITOS" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/DOS_PITOS.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>Dos ases</span>
                    </a>
                    <a className="button-opt sign" href="#" data-id="TRES_PITOS" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/TRES_PITOS.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>Medias de ases</span>
                    </a>
                    <a className="button-opt sign" href="#" data-id="TREINTA" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/TREINTA.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>30</span>
                    </a>
                    <a className="button-opt sign" href="#" data-id="SOLOMILLO" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/SOLOMILLO.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>Solomillo</span>
                    </a>
                    <a className="button-opt sign" href="#" data-id="DUPLES" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/DUPLES.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>Duples</span>
                    </a>
                    <a className="button-opt sign" href="#" data-id="TRES_CERDOS" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/TRES_CERDOS.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>Medias de reyes</span>
                    </a>
                    <a className="button-opt sign" href="#" data-id="TREINTAYUNA" style={{display: "none"}}>
                        <img className="signs-opt" src="static/img/sign/TREINTAYUNA.png" width="53" height="53" alt="Bg Player Name"/>
                        <span>31</span>
                    </a>
                </div>
            </section>
        </div>
    );
}

export default PlayZone;