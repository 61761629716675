import React from 'react';

const ProfileSection = () => {
    return (
        <div>
            <section id="section-profile" className="section"  style={{display: "none"}}>

                {/* <!-- PESTAÑAS --> */}
                <div className="submenu">
                    <ul>
                        <li className="first"></li>
                        <li className="current"><a href="/#" className="btn-submenu" data-link="profile">Mi perfil</a></li>
                        <li><a href="/#" className="btn-submenu" data-link="money"  >Mi monedero</a></li>
                        <li><a href="/#" className="" id="btn-submenu-invitar-amigo">Invitar amigos</a></li>
                        <li><a href="/#" className="btn-submenu" data-link="info"   >Mis datos</a></li>
                        <li><a href="/#" className="btn-submenu" data-link="ajustes">Ajustes</a></li>
                        <li><a href="/#" className="logout">Cerrar Sesión</a></li>
                    </ul>
                </div>

                {/* <!-- 1 - MI PERFIL --> */}
                <section id="profile" className="subsection bg-white section-default" style={{display: "none"}}>

                    <div className="subsection-profile">

                        <a href="/#"  id="user-character-image" className="btn-submenu selection my-user-character-big" data-link="characterSelection"/>

                        <div className="selectPers hidden">
                            <span className="first">Selecciona un</span> <br/>
                            <span className="last">PERSONAJE</span>
                        </div>

                        <div className="placement">
                            <img src="static/img/other/Foto_perfil_defecto.png" width="371" height="372" alt="Foto de perfil" id="user-image-big" className="my-user-imageProfile"/>
                            <a href="/#" id="btn-hacer-foto">
                                <img src="static/img/icon/ico-camara.png" width="42" height="34" alt="Ico Camara"/>
                                <span>Hacer foto</span>
                            </a>

                            <a href="/#" className="clip">
                                <input id="input-img-profile" type="file" className="inputfile"  name="file" accept="image/*"/>
                                <img src="static/img/icon/ico-clip.png" width="31" height="39" alt="Ico Clip"/>
                                <label htmlFor="input-img-profile">&nbsp;&nbsp;Colocar foto</label>
                            </a>
                        </div>
                        <div className="datas">
                            <div className="user">
                                <label>Usuario:</label>
                                <span className="name my-user-username"></span>
                            </div>
                            <div className="ranking" style={{height: "85px"}}>
                                <label>Ranking:</label>
                                <div className="cups-ranking"/>
                            </div>
                            <div className="password">
                                <div className="box">
                                    <label>Contraseña</label>
                                    <span>**************</span>
                                    <a href="/#" id="edit-password-link">Editar</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 1.1 -  MI PERFIL > BTN - SELECTION CHARACTER > nueva ventana --> */}
                <section id="characterSelection" className="subsection bg-grey"  style={{display: "none"}}>

                    <div className="subsection-charSelection">

                        {/* <!-- MITAD IZDA --> */}
                        <div className="left">

                            {/* <!-- Pestañas --> */}
                            <nav className="menu">
                                <ul>
                                    <li><a href="/#" className="btn-char-selection current" data-link="biography"     id="biography-menu">BIOGRAFÍA</a></li>
                                    <li><a href="/#" className="btn-char-selection middle"  data-link="playing-style" id="playing-style-menu">ESTILO DE JUEGO</a></li>
                                </ul>
                            </nav>

                            <div id="biography"     className="description" />
                            <div id="playing-style" className="description hidden" />

                            <div className="slide">
                                <div className="roles" id="roles" />
                                <div className="mandos">
                                    <span className="line"></span>
                                    <a href="/#" data-mando="1"><img src="static/img/button/carrusel_verde.png" width="12" height="12" alt="Carrusel Verde"/></a>
                                    <a href="/#" data-mando="2"><img src="static/img/button/carrusel_gris.png"  width="12" height="12" alt="Carrusel Gris" /></a>
                                    <a href="/#" data-mando="3"><img src="static/img/button/carrusel_gris.png"  width="12" height="12" alt="Carrusel Gris" /></a>
                                    <span className="line last"/>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Character img--> */}
                        <div className="role-player">
                            <input type="hidden" id="input-character"/>
                            <img src="" width="577" height="487" alt="Marga" data-id="1"/>
                            <span className="arround"/>
                            <a href="/#" className="save" id="save-character"> <span>Aceptar</span>  </a>
                        </div>
                    </div>
                </section>

                {/* <!-- 2 - MI MONEDERO  --> */}
                <section id="money" className="subsection bg-white"  style={{display: "none"}}>

                    <div className="subsection-monedero">

                        <div className="left">
                            <h2>Mi monedero</h2>
                            <div className="box">
                                <div className="credits ">
                                    <label>Tengo</label>
                                    <span className="number my-user-credits"></span>
                                    <span className="text">CRÉDITOS</span>
                                </div>
                            </div>
                        </div>
                        <div className="right">
                            <form id="form-packs"  action="#" method="POST" name="form-packs" >
                                <input type="hidden" id="DEVELOPER_ID"  name="DEVELOPER_ID"  value=""/>
                                <input type="hidden" id="ORDER_ID"      name="ORDER_ID"      value=""/>
                                <input type="hidden" id="RETURN"        name="RETURN"        value=""/>
                                <input type="hidden" id="CANCEL_RETURN" name="CANCEL_RETURN" value=""/>
                                <input type="hidden" id="DIGEST"        name="DIGEST"        value=""/>

                                <ul className="packs"> </ul>
                            </form>
                        </div>
                    </div>
                </section>

                {/* <!-- 4 - Mis Datos --> */}
                <section id="info" className="subsection bg-white" style={{display: "none"}}>

                    <div className="mydatas">
                        <div className="head"><h2>Mis datos</h2><a href="/#" data-link="baja" className="link">Darme de baja</a></div>
                        <div className="datas">
                            <div className="box">
                                <label>Nombre:</label>
                                <span className="my-user-name"></span>
                            </div>
                            <div className="box middle">
                                <label>Apellidos:</label>
                                <span className="my-user-surname"></span>
                            </div>
                            <div className="box">
                                <label>Fecha de nacimiento:</label>
                                <span className="my-user-birthdate"></span>
                            </div>
                        </div>
                        <div className="lines"><span></span><span></span></div>
                        <div className="datas">
                            <div className="box">
                                <label>Correo electrónico:</label>
                                <span className="my-user-email"></span>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- 4.1  Mis Datos > DARME DE BAJA --> */}
                <section id="baja" className="subsection" style={{display: "none"}}>

                    <div className="formulario">
                        <div className="left">
                            <h2>Darme de baja</h2>
                            <label>¿Por qué quieres darte de baja?</label>
                            <select id="select-baja">
                                <option>Comportamientos inadecuados</option>
                                <option>Seguridad</option>
                                <option>Otros</option>
                            </select>
                        </div>
                        <div className="right">
                            <label>HAZ UN COMENTARIO PARA QUE PODAMOS SABER <strong>TUS RAZONES</strong></label>
                            <textarea id="input-reason"></textarea>
                            <a className="cancel link" href="/#" data-link="info">Cancelar</a>
                            <a className="baja" href="/#" id="btn-disable">Darme de baja</a>
                        </div>
                    </div>
                </section>

                {/* <!-- 5 - AJUSTES --> */}
                <section id="ajustes" className="subsection bg-white" style={{display: "none"}}>

                    <div className="ajustes">
                        <span className="title">Ajustes</span>
                        <div className="configuracion">
                            <div className="bloq">
                                <button data-id="chat" className="slide active"></button>
                                <div className="bloqTxt">
                                    <span className="confTitle">Chat</span>
                                    <p>Si tienes el chat activo podrás comunicarte con tu pareja y rivales durante las partidas
                                        multijugador y durante los torneos.</p>
                                </div>
                            </div>
                            <div className="bloq">
                                <button data-id="sound" className="slide active"></button>
                                <div className="bloqTxt">
                                    <span className="confTitle">Sonido</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default ProfileSection;
