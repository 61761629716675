import React from 'react';

const TournamentSection = () => {
    return (
        <section id="section-tournament" className="section" style={{display: "none"}}>
            {/* <!-- PESTAÑAS --> */}
            <div className="submenu">
                <ul>
                    <li className="first"></li>
                    <li className="current">
                        <a href="/#" id="btn-tab-tournament-list"       className="btn-submenu" data-link="tour-list"       >Jugar nuevo torneo</a></li>
                    <li><a href="/#" id="btn-tab-tournament-invitation" className="btn-submenu" data-link="tour-invitations">Invitaciones pendientes</a></li>
                    <li><a href="/#" id="btn-tab-tournaments-in-game"   className="btn-submenu" data-link="tour-in-game"    >Mis torneos en juego</a></li>
                    <li><a href="/#" id="btn-tab-tournaments-history"   className="btn-submenu" data-link="tour-history"    >Histórico de torneos</a></li>
                    <li className="last"></li>
                </ul>
            </div>

            {/* <!-- 1. LISTADO TORNEOS --> */}
            <section id="tour-list" className="subsection bg-white section-default" style={{display: "none"}}>

                <div className="subsection-tour-list">

                    <div className="filter-header">

                        <span className="text"><img src="static/img/button/titulo-torneos.png" width="198" height="65"
                                                alt="Titulo Mulit"/></span>

                        <div className="filters">
                            {/* <!-- FILTRO --> */}
                            <div className="kings">
                                <span className="title">Elige reyes</span>
                                <div className="buttons">
                                    <button className="btn-filter-reyes"          data-value="false">4</button>
                                    <button className="btn-filter-reyes selected" data-value="true">8</button>
                                </div>
                            </div>
                            <div className="guys">
                                <span className="title">Elige chicos</span>
                                <div className="buttons">
                                    <button className="btn-filter-chicos selected" data-value="1">1</button>
                                    <button className="btn-filter-chicos"          data-value="3">3</button>
                                    <button className="btn-filter-chicos"          data-value="5">5</button>
                                </div>
                            </div>
                            <div className="speed">
                                <span className="title">Velocidad</span>
                                <div className="buttons">
                                    <button className="btn-filter-speed "         data-value="false">Normal</button>
                                    <button className="btn-filter-speed selected" data-value="true">Rapida</button>
                                </div>
                            </div>

                            <div className="guys">
                                <span className="title">Nivel</span>
                                <div className="buttons">
                                    <button className="btn-filter-level selected" data-value="1">1</button>
                                    <button className="btn-filter-level"          data-value="2">2</button>
                                    <button className="btn-filter-level"          data-value="3">3</button>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <!-- RESULTADOS --> */}
                    <div className="scrollable-section">
                        <ul id="filtered-tournament-list"></ul>
                    </div>

                </div>
            </section>

            {/* <!-- 1.1.  FICHA TORNEO --> */}
            <section id="tour-detail" className="subsection bg-dark" style={{display: "none"}}>

                <div className="subsection-tour-detail" >

                    {/* <!-- MAIN --> */}
                    <div className="fichaTorneo">
                        <div className="table">

                            <div className="supLine">
                                {/* <!-- PROMO CODE --> */}
                                <button className="bt1 btn-tour-code">
                                    <span className="titulo">Código </span>
                                    <div className="dot">
                                        <span className="type">PROMOCIONAL</span>
                                        <img src="static/img/icon/icono_users.png" width="58" height="41" alt="Icono Users"/>
                                    </div>
                                </button>
                                {/* <!-- PRIZES --> */}
                                <button className="bt2 btn-tour-prizes">
                                    <span className="titulo">Premios</span>
                                    <div className="dot">
                                        <span className="type">TORNEO</span>
                                        <img src="static/img/icon/icono_premios.png" width="71" height="58" alt="Icono Premios"/>
                                    </div>
                                </button>
                            </div>


                            <div className="supLine">

                                {/* <!-- PHASES --> */}
                                <button className="bt3 btn-tour-phases"/>

                                {/* <!-- RULES --> */}
                                <button className="bt4 btn-tour-rules">
                                    <span className="titulo ">Reglas</span>
                                    <div className="dot">
                                        <span className="type">TORNEO</span>
                                        <img src="static/img/icon/icono_reglas.png" width="47" height="51" alt="Icono Reglas"/>
                                    </div>
                                </button>
                            </div>

                            <div className="botLine">

                                {/* <!-- TERMS --> */}
                                <span className="check">
                                    <input id="tour-detail-terms-checkbox" type="checkbox"  /> He leido y acepto las reglas y condiciones del torneo
                                </span>

                                {/* <!-- PROMO CODE --> */}
                                <input id="tour-promo-code" type="hidden"/>

                                {/* <!-- STATE 1 --> */}
                                <div id="tour-detail-state-1" className="tour-detail-state">
                                    <button className="bt aceptar" id="btn-tour-detail-register-alone" data-id="">INSCRIBIRME SOLO/A     </button>
                                    <button className="bt aceptar" id="btn-tour-detail-register-couple">INSCRIBIRME CON MI PAREJA  </button>
                                </div>

                                <div id="tour-detail-state-2" className="tour-detail-state hidden">
                                    <label>YA ESTÁS INSCRITO A ESTE TORNEO</label>
                                </div>

                                <div id="tour-detail-state-3" className="tour-detail-state hidden">
                                    <button className="bt aceptar"  id="btn-accept-invitation">ACEPTAR INVITACIÓN</button>
                                    <button className="bt rechazar" id="btn-refuse-invitation">RECHAZAR INVITACIÓN</button>
                                </div>

                                <div id="tour-detail-state-4" className="tour-detail-state hidden">
                                    <label> YA ESTÁS INSCRITO PERO ESTÁS ESPERANDO A UN COMPAÑERO </label>
                                </div>

                            </div>

                        </div>


                        {/* <!-- RIGHT --> */}
                        <div className="rightColumn">

                            {/* <!-- LOGO --> */}
                            <div className="header">
                                <img src="static/img/button/titulo-torneos-lit.png" width="177" height="52" alt="Titulo Torneos Lit"/>
                                <div className="text">  <span id="tour-detail-name" className="first">Super Tour</span>                            </div>
                            </div>

                            {/* <!-- DATA --> */}
                            <div className="texto">
                                <div id="tour-detail-price"     className="tour-detail-data" >Inscripción: <span/> </div>
                                <div id="tour-detail-startdate" className="tour-detail-data" >Inicio:      <span/> </div>
                                <div id="tour-detail-enddate"   className="tour-detail-data" >Fin:         <span/> </div>
                                <div id="tour-detail-phases"    className="tour-detail-data" >Número Fases: <span/> </div>
                            </div>

                            <div>
                                <div className="line">
                                    <div className="reyes">
                                        <span id="tour-detail-reyes" className="number">X</span>
                                        <span className="text"> REYES</span>
                                    </div>
                                    <div className="chicos">
                                        <span id="tour-detail-chicos" className="number">X</span>
                                        <span className="text"> CHICOS</span>
                                    </div>
                                    <div className="speed">
                                        <span id="tour-detail-speed" className="text">X</span>
                                    </div>
                                </div>
                            </div>

                            <div className="credits">
                                <span className="title">total premios</span>
                                <div className="numero">
                                    <span className="star"><img src="static/img/icon/grey-star.png" width="11" height="10" alt="Grey Star"/></span>
                                    <span id ="tour-detail-prize" className="number">200 C</span>
                                    <span className="star"> <img src="static/img/icon/grey-star.png" width="11" height="10" alt="Grey Star"/></span>
                                </div>
                            </div>

                            {/* <!-- SALIR --> */}
                            <div className="button"> <a className="bt cancel" href="/#" id="btn-tour-exit">SALIR</a> </div>

                        </div>
                    </div>
                </div>

            </section>

            {/* <!-- 2. INVITACIONES PENDIENTES --> */}
            <section id="tour-invitations" className="subsection bg-white" style={{display: "none"}}>
            <div className="tour-inv-class">
                    <ul id="tour-invitations-list"></ul>
            </div>
            </section>

            {/* <!-- 3. TORNEOS EN JUEGO --> */}
            <section id="tour-in-game" className="subsection bg-white" style={{display: "none"}}>

                <div className="tour-in-game-section">

                    {/* <!-- TITTLE --> */}
                    <span className="title">Mis torneos en juego</span>

                    <div className="listado">
                        {/* <!-- HEADER --> */}
                        <div className="head">
                            <div className="titulo nombre">Torneo</div>
                            <div className="titulo comp">Compañero</div>
                            <div className="titulo credits">Premio</div>
                            <div className="titulo fecha">Próxima partida</div>
                            <div className="titulo lupa"></div>
                        </div>
                        {/* <!-- CONTENT --> */}
                        <div className="contenido"> </div>

                    </div>
                </div>
            </section>

            {/* <!-- 4. HISTORY --> */}
            <section  id="tour-history" className="subsection bg-white" style={{display: "none"}}>

                <div className="tour-history-section">

                    {/* <!-- TITTLE --> */}
                    <span className="title">Histórico de torneos</span>

                    <div className="listado">

                        {/* <!-- HEADER --> */}
                        <div className="head">
                            <div className="titulo nombre">Torneo</div>
                            <div className="titulo comp">Compañero</div>
                            <div className="titulo credits">Resultado</div>
                            <div className="titulo lupa"></div>
                        </div>

                        {/* <!-- CONTENT --> */}
                        <div className="contenido"> </div>
                    </div>
                </div>
            </section>

            {/* <!-- 4.1 HISTORY DETAIL (LUPA) --> */}
            <section  id="tour-history-detail" className="subsection bg-white" style={{display: "none"}}>

                {/* <!-- DETALLE TORNEO --> */}
                <div className="historicoFichaTorneos">

                    <input type="hidden" id="tour-detail-id"/>
                    <input type="hidden" id="tour-detail-promo-code"/>

                    {/* <!-- TOUR INFO HEADER --> */}
                    <div style={{paddingTop: "10px"}}>
                        <span id="tour-history-tour-name" className="title">El club de la montaña</span>

                        <div className="buttons">
                            <button className="btn-tour-prizes">PREMIOS</button>
                            <button className="btn-tour-rules">REGLAS DEL TORNEO</button>
                        </div>
                    </div>

                    {/* <!-- PHASES LIST HEADER --> */}
                    <div className="head">
                        <ul id="tour-history-phases-list"/>
                    </div>

                    {/* <!-- CONTENT --> */}
                    <div className="detalleHistoricoTorneos">

                        <div className="phase">

                            {/* <!-- DATOS-PARTIDA --> */}
                            <div className="supLine">
                                <div className="bloqleft">
                                    <div className="resultado">
                                        <div className="titulo">RESULTADO</div>
                                        <div className="number match-puntuacion"></div>
                                    </div>
                                    <div className="datos">
                                        <div className="id">     <span className="titu">Partida: </span> <span className="match-name">       </span> </div>
                                        <div className="balance"><span className="titu">Balance: </span> <span className="num match-balance"></span> </div>
                                    </div>
                                </div>

                                <div className="bloqright">
                                    <div className="otrosdatos">
                                        <div>               <span className="titu">Fecha:</span>    <span className="num match-fecha"></span>   </div>
                                        <div className="middle"><span className="titu">Compañero:</span><span className="num match-partner"></span> </div>
                                    </div>
                                </div>

                                <div className="bloqright">
                                    <div className="otrosdatos">
                                        <div><span className="titu">Rivales:</span><span className="num match-opponents"></span></div>
                                    </div>
                                </div>
                            </div>

                            {/* <!-- CHICOS --> */}
                            <div className="midLine">  </div>

                            {/* <!-- RONDAS --> */}
                            <div className="listado">

                                {/* <!-- CABECERAS --> */}
                                <div className="head">
                                    <div className="titulo gran">Grande</div>
                                    <div className="titulo peq">Pequeña</div>
                                    <div className="titulo pares">Pares</div>
                                    <div className="titulo juego">Juego / Punto</div>
                                    <div className="titulo resu">Resul.Mano</div>
                                    <div className="titulo acum">Acum.Chico</div>
                                </div>

                                {/* <!-- DATOS --> */}
                                <div className="puntuaciones">  </div>
                            </div>
                        </div>

                        <div className="noPhaseInfo" style={{display: "none"}}><span>Esta fase no se ha llegado a jugar</span> </div>

                    </div>

                </div>

            </section>

            {/* <!-- 5. TOUR-STEPs --> */}
            <section id="tour-ingame" className="subsection bg-white" style={{display: "none"}}>

                <div className="subsection-tour-ingame">

                    {/* <!-- TITTLE --> */}
                    <p className="section-tittle">Estado del torneo</p>

                    {/* <!-- TOUR Steps --> */}
                    <div className="tour-steps">
                        {/* <el-steps :active="activePhase" align-center="true">
                            <el-step :data-phase-id="index" v-for="(phase, index) in phases" :key="index" icon="fa fa-trophy fa-2x" :title="phase.title" :description="phase.description">

                            </el-step>
                        </el-steps> */}
                    </div>

                    {/* <!-- PLAY BUTTON --> */}
                    <div className="tour-steps-btn-block">
                        <button id="btn-tour-play-now" data-id="" className="btn-green" >Jugar</button>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default TournamentSection;
