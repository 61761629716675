import React from 'react';
import useScript from '../../hooks/useScript';

import './Register.scss';

const Register = () => {
    // <!-- 3rd Party LIBs -->
    LoadScripts();
    return (
        <div className="profile bg-tapete">
            {/* <!-- TERMS POPUP --> */}
            <div className="body1">
                <div className="floatContainerFases terminos popup hidden">
                    <a className="close btn-close-popup"/>
                    <span className="title">Términos y condiciones de uso</span>
                    <div className="bloque"> </div>
                </div>
            </div>
            <section className="registerForm">
                <div className="logotipo">MasterMus</div>
                <div className="boxShadow">
                    <div className="bloq paralell">
                        <input type="hidden" id="input-rs"          value=""/>
                        <input type="hidden" id="input-token"       value=""/>
                        <input type="hidden" id="input-facebook-id" value=""/>
                        <input type="hidden" id="input-picture"     value=""/>
                        <div className="textInput">
                            <div>
                                <label>NOMBRE</label>
                                <input type="text" placeholder="Nombre" className="uno" id="input-name"/>
                                <p className="help-block hidden">Debe escribir su nombre</p>
                            </div>
                            <div>
                                <label>APELLIDOS</label>
                                <input type="text" placeholder="Apellidos" className="dos" id="input-lastName"/>
                                <p className="help-block hidden">Debe escribir sus apellidos</p>
                            </div>
                            <div>
                                <label>FECHA DE NACIMIENTO</label>
                                <input type="date" className="tres" id="input-birthdate" placeholder='DD-MM-YYYY' min="01-01-1900" max="31-12-2050"/>
                                <p className="help-block hidden">Debe escribir su fecha de nacimiento</p>
                            </div>
                            <div>
                                <label>NOMBRE DE USUARIO</label>
                                <input type="text" placeholder="Nombre" className="cuatro" id="input-user"/>
                                <p className="help-block hidden">Debe escribir su nombre de usuario</p>
                            </div>
                        </div>
                    </div>
                    <div className="bloq paralell">
                        <div className="textInput">
                            <div>
                                <label>EMAIL</label>
                                <input type="email" placeholder="ejemplo@mastermus.es" className="cinco" id="input-email"/>
                                <p className="help-block hidden">Debe escribir su email</p>
                            </div>
                            <div className="div-password">
                                <label>ESCRIBE CONTRASEÑA</label>
                                <input type="password" placeholder="********" className="seis" id="input-password"/>
                                <p className="help-block hidden">Debe escribir su contraseña</p>
                            </div>
                            <div className="div-password">
                                <label>REPITE CONTRASEÑA</label>
                                <input type="password" placeholder="********" className="siete" id="input-password2"/>
                                <p className="help-block hidden">Debe repetir su contraseña</p>
                            </div>
                            <div>
                                <input id="input-terms" type="checkbox" />
                                <a className="noAccount" href="#" id="show-terms">Acepto condiciones de uso</a>
                                <p className="help-block hidden">Debe aceptar las condiciones de uso</p>
                            </div>
                        </div>
                    </div>
                    <a className="register" href="#" id="btn-register">Registrarme</a>
                    <a className="cancel" href="/">Cancelar</a>
                </div>
            </section>
        </div>
    );
}

export default Register;

function LoadScripts() {
    // <!-- 3rd Party LIBs -->
    useScript('static/js/libs/jQuery/jquery.min.js');

    // <!-- CONFIG -->
    useScript('static/js/globals.js');
    useScript('static/js/config/config-LOCAL.js');

    // <!-- WEBSOCKETS -->
    useScript('static/js/api/api_commons.js');
    useScript('static/js/api/api_login.js');
    useScript('static/js/libs/facebook.js');
    useScript('static/js/register.js');
}

