import React from 'react';
import useScript from '../../hooks/useScript';

import './RecoverPassword.scss';

const RecoverPassword = () => {
    // <!-- 3rd Party LIBs -->
    LoadScripts();
    return (
        <section className="recoverPassForm recuperar">
            <div className="logotipo">MasterMus</div>
            <div className="boxShadow">
                <div className="bloq">
                    <span>RECUPERAR CONTRASEÑA</span>
                </div>
                <div className="bloq">
                        <div className="textInput">
                            <div>
                                <input type="text" id="input-email" placeholder="Introduce tu email..."></input>
                            </div>
                        </div>
                </div>
                <div className="bloq">
                    <a className="enter" href="#" id="btn-recovery">Recuperar contraseña</a>
                </div>
            </div>
        </section>
    );
}

export default RecoverPassword;

function LoadScripts() {
    useScript('static/js/libs/jQuery/jquery.min.js');

    // <!-- CONFIG -->
    useScript('static/js/globals.js');
    useScript('static/js/config/config-LOCAL.js');

    // <!-- WEBSOCKETS -->
    useScript('static/js/api/api_commons.js');
    useScript('static/js/passRecovery.js');
}