import React from 'react';

import './Menu.scss';
import fotoperfildefecto from "../../static/img/other/foto_perfil_defecto.png";
import icoCards from "../../static/img/icon/ico-cards.png";
import icoCup from "../../static/img/icon/ico-cup.png";
import icoRanking from "../../static/img/icon/ico-ranking.png";
import icoMensaje from "../../static/img/icon/ico-mensaje.png";
import icoGreenUsers from "../../static/img/icon/ico-green-users.png";
import icoGreenHelp from "../../static/img/icon/ico-green-help.png";

const Menu = () => {
    return (
        <header id="headerPrueba">
            <nav>
                <ul id="menu">
                    {/* LOGO */}
                    <li className="logo"><a className="btn-home" href="/"><h1 className="h1class">MasterMus</h1></a></li>
                    {/* PROFILE */}
                    <li className="hover datos ho">
                        <a href="/#" className="btn-menu" data-link="profile">
                            <div className="sec1">
                                <img src={fotoperfildefecto} width="69" height="69" alt="" id="user-image" className="my-user-imageProfile"/>
                                <span>Mi cuenta</span>
                            </div>
                            <div className="sec2">
                                <div className="cups"></div>
                                <label className="name my-user-username"></label>
                                <div className="creditos"><label>CRÉDITOS</label>
                                    <span className="credits credits-amount my-user-credits"></span>
                                </div>
                            </div>
                        </a>
                    </li>
                    {/* GAME */} 
                    <li className="hover action ho">
                        <a href="/#" className="btn-menu" data-link="game">
                            <img src={icoCards} width="58" height="58" alt="Ico Cards"/>
                            <span>Partidas</span>
                        </a>
                    </li>
                    {/* TOURNAMENT */} 
                    <li className="hover action ho">
                        <a href="/#" className="btn-menu" data-link="tournament">
                            <img src={icoCup} width="58" height="58" alt="Ico Cup"/>
                            <span>Torneos</span>
                        </a>
                    </li>
                    {/* RANKING */} 
                    <li className="hover action ho">
                        <a href="/#" className="btn-menu" data-link="ranking">
                            <img src={icoRanking} width="58" height="58" alt="Ico Ranking"/>
                            <span>Ranking</span>
                        </a>
                    </li>
                    {/* MESSAGES */} 
                    <li className="hover action ho">
                        <a href="/#" id="btn-messages" className="btn-menu" data-link="messages">
                            <img src={icoMensaje} width="54" height="58" alt="Ico Mensajes"/>
                            <span>Mensajes</span>
                            <span className="messages hidden">0</span>
                        </a>
                    </li>
                    {/* CONECTADOS - AYUDA  */} 
                    <li className="last">
                        <a href="/#">
                            <img src={icoGreenUsers} width="28" height="18" alt="Ico Green Usuarios"/>
                            <span className="user-count">0</span>
                        </a>
                        <a href="/#" className="btn-menu" data-link="help">
                            <img src={icoGreenHelp} width="28" height="25" alt="Ico Green Help"/>
                            <span>Ayuda</span>
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Menu;
