import React from 'react';

const MessagesSection = () => {
    return (
        <section id="section-messages" className="section" style={{display: "none"}}>

            {/* <!-- PESTAÑAS (N/A) --> */}
            <div className="submenu">  </div>

            <section id="messages" className="subsection bg-white section-default" style={{display: "none"}} >

                <div className="messages">
                    <div className="left">
                        <a href="/#" id="btn-new-conversation">
                            <button> <img src="static/img/button/bt-new-mns.jpg" width="327" height="62" alt="Bt New Mns"/> </button>
                        </a>
                        <ul id="conversations-list"></ul>
                    </div>
                    <div className="right">
                        <ul id="conversation-messages">
                            <div style={{color: "#888", textAlign: "center", width: "100%", paddingTop: "100px"}}>
                                <span>Selecciona un Chat</span>
                            </div>
                        </ul>
                        <div className="pad">
                            <textarea id="chat-input-msg" style={{resize: "none"}}></textarea>
                            <button id="btn-send" data-id="">
                                <img src="static/img/button/send.png" width="53" height="40" alt="Bt Cuadrados"/>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default MessagesSection;