import React from 'react';

const GameSection = () => {
    return (
        <div>
            <section id="section-game" className="section" style={{display: "none"}}>
                {/* <!-- PESTAÑAS --> */}
                <div className="submenu">
                    <ul>
                        <li className="first"></li>
                        <li className="current">
                            <a href="/#" id="btn-tab-game" className="btn-submenu" data-link="game">Jugar partida</a></li>
                        <li><a href="/#" id="btn-tab-invitation" className="btn-submenu" data-link="pending">Invitaciones pendientes</a></li>
                        <li><a href="/#" id="btn-tab-historic" className="btn-submenu" data-link="historico">Histórico de partidas</a></li>
                        <li className="last"></li>
                    </ul>
                </div>
                {/* <!-- 1. JUGAR PARTIDA --> */}
                <section id="game" className="subsection bg-dark section-default" style={{display: "none"}}>
                    <div className="subsection-partidas">
                        {/* <!-- COLUMNA IZDA --> */}
                        <div className="left-col column">
                            {/* <!-- TITULO --> */}
                            <div className="first">
                                <img src="static/img/other/titulo-multi.png" width="540" height="61" alt="Titulo Multi"/>
                            </div>
                            {/* <!-- BOTONES --> */}
                            <div className="bt">
                                {/* <!-- CREA MULTI --> */}
                                <div className="button">
                                    <a href="" id="btn-create-multi" >
                                        <img src="static/img/button/bt-crear-partida.jpg" width="486" height="150" alt="Crea tu partida"/>
                                    </a>
                                </div>
                                {/* <!-- BUSCADOR MULTI --> */}
                                <div className="button">
                                    <a href="" id="btn-search-m" >
                                        <img src="static/img/button/bt-encuentra-gusto.jpg" width="486" height="151" alt="Encuentra la partida que mas te guste"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- SEPARADOR CENTRAL --> */}
                        <div className="center-col column"/>
                        {/* <!-- COLUMNA DCHA --> */}
                        <div className="right-col column">
                            {/* <!-- TITULO --> */}
                            <div className="first" style={{height: "65px"}}>
                                <img src="static/img/other/titulo-express.png" width="548" height="49" alt="Titulo Express"/>
                            </div>
                            {/* <!-- BOTONES --> */}
                            <div className="bt">
                                {/* <!-- EXPRESS A --> */}
                                <div className="button">
                                    <a href="/#" id="btn-express-a"  >
                                        <img src="static/img/button/bt-express-solo.jpg" width="485" height="150" alt="Bt Express Solo"/>
                                    </a>
                                </div>
                                {/* <!-- EXPRESS B{ --> */}
                                <div className="button">
                                    <a href="/#" id="btn-express-b">
                                        <img src="static/img/button/bt-express-b.jpg" width="485" height="151" alt="s"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- 1.1. PREPARTIDAS{ --> */}
                <section id="prepartida" className="subsection bg-dark"  style={{display: "none"}}>
                    <div className="subsection-prepartida">
                        {/* <!-- MESA{ --> */}
                        <div className="table">
                            {/* <!-- TOP PLAYER{ --> */}
                            <div className="player_TOP" />

                            <div className="middle">
                                {/* <!-- LEFT PLAYER{ --> */}
                                <div className="player_LEFT middle-row " />
                                {/* <!-- TABLE IMG{ --> */}
                                <div className="middle-row-table">
                                    <img src="static/img/icon/icono_mesa.png" width="230" height="229" alt="Icono Mesa"/>
                                </div>
                                {/* <!-- RIGHT PLAYER{ --> */}
                                <div className="player_RIGHT middle-row" />
                            </div>

                            {/* <!-- BOTTOM PLAYER{ --> */}
                            <div className="player_BOTTOM"/>
                        </div>
                        {/* <!-- MENU DCHA{ --> */}
                        <div className="rightColumn">
                            <img id="prepartida-stage-preview" src="" width="1920" height="1080" className="rightColImg hidden"/>
                            <input id="id_room" type="hidden"/>
                            {/* <!-- HEADER{ --> */}
                            <div id="prepartida-title" className="header">
                                <img id="prepartida-icon" src="static/img/icon/icono_expressa.png" width="53" height="61" alt="Icono Expressa"/>
                                <div className="text">
                                    <span className="first"/>
                                    <span className="second"/>
                                    <span className="third"/>
                                </div>
                            </div>
                            {/* <!-- TEXTO{ --> */}
                            <div id="prepartida-description" className="texto"/>
                            {/* <!-- CONFIGURACION{ --> */}
                            <div id="prepartida-data" className="line">
                                <div className="cartas">
                                    <span className="number">8</span>
                                    <span className="text"> REYES</span>
                                </div>
                                <div className="personas">
                                    <span className="number">1</span>
                                    <span className="text"> CHICO</span>
                                </div>
                                <div className="nivel">
                                    <span className="text">NORMAL</span>
                                </div>
                            </div>
                            {/* <!-- BOTONES{ --> */}
                            <div id="prepartida-btn-group-owner" className="button-group hidden" style={{marginTop: "20px", marginLeft: "10px", padding: "0px 5%" }}>
                                <button id="btn-game-cancel" className="btn-red btn-block">CANCELAR</button>
                                <button id="btn-game-start" className="btn-green btn-block btn-disabled">ACEPTAR</button>
                            </div>
                            <div id="prepartida-group-buttons-guess" className="button-group hidden" style={{marginTop: "20px", padding: "0px 5%"}}>
                                <button id="btn-game-exit" className="btn-red btn-block">SALIR</button>
                            </div>
                            <div id="tour-waiting-couple-buttons-group" className="button-group hidden" style={{marginTop: "20px", padding: "0px 5%"}}>
                                <button id="tour-waiting-couple-exit" className="btn-red btn-block">SALIR</button>
                            </div>
                            {/* <!-- Configuracion de partida multi--> */}
                            <div id="prepartida-config" className="button-group hidden">
                                {/* <!-- Seleccion escenario{ --> */}
                                <div id="group-multi-1">
                                    <span className="titulo">Elige un escenario</span>
                                    <span className="marco">
                                        <a href="/#" className="bt btn-submenu" data-link="stage-selector">ELEGIR ESCENARIO</a>
                                    </span>
                                </div>
                                {/* <!-- Seleccion parametros partida{ --> */}
                                <div id="group-multi-2" className="hidden">
                                    <span className="titulo">Elige nº de reyes</span>
                                    <div className="desplegables">
                                    <span className="marcomin">
                                        <a className="bt" data-value="true" href="/#" id="btn-reyes">
                                            <span>8 REYES</span>
                                            <img src="static/img/icon/flecha_down.png" width="12" height="10" alt="Flecha Abajo"/>
                                        </a>
                                    </span>
                                    <span className="marcomin">
                                        <a className="bt" data-value="1" href="/#" id="btn-chicos">
                                            <span>1 CHICO</span>
                                            <img src="static/img/icon/flecha_down.png" width="12" height="10" alt="Flecha Abajo"/>
                                        </a>
                                    </span>
                                    <span className="marcomin">
                                        <a className="bt" data-value="false" href="/#" id="btn-speed">
                                            <span>NORMAL</span>
                                            <img src="static/img/icon/flecha_down.png" width="12" height="10" alt="Flecha Abajo"/>
                                        </a>
                                    </span>
                                    </div>
                                </div>
                                {/* <!-- BTN Crear partida{ --> */}
                                <span className="marco">
                                    <a id="btn-game-cancel" className="bt btn-game-multi grey" href="/#">CREAR PARTIDA</a>
                                    {/* <a id="btn-game-cancel" className="bt" href="/#">CANCELAR</a> */}
                                </span>
                                {/* <div id="group-multi-crear">
                                    <span className="marco">
                                        <button id="btn-game-cancel" className="btn-red btn-block">CANCELAR</button>
                                    </span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- 1.1.1 SELECCION DE ESCENARIO{ --> */}
                <section id="stage-selector"  className="subsection bg-grey" style={{display: "none"}}>
                    <div className="left">
                        {/* <!-- Nombre Escenario{ --> */}
                        <nav className="menu">
                            <ul>  <li><a href="/#" className="current" id="name-stage">Escenario Test</a> </li>  </ul>
                        </nav>
                        {/* <!-- Description{ --> */}
                        <div className="description" id="description-stage">
                            <p>Descripcion de prueba</p>
                        </div>
                        {/* <!-- Carrusel{ --> */}
                        <div className="slide">
                            <div id="stages"       className="roles"/>
                            <div id="mandos-stage" className="mandos" > <span className="line"/> </div>
                        </div>
                    </div>
                    {/* <!-- MITAD DERECHA{ --> */}
                    <div className="role-player">
                        <img src="static/img/default/stage.jpg" width="577" height="487" alt="" data-id="" id="main-scene-image"/>
                        <span className="arround"/>
                        <a href="/#" className="save" id="save-stage">
                            <span>Aceptar</span>
                        </a>
                    </div>
                </section>
                {/* <!-- 1.2. BUSCADOR PARTIDAS MULTI{ --> */}
                <section id="search-room" className="subsection bg-white" style={{display: "none"}}>
                    <div className="subsection-search-room">
                        <div className="head">
                            <img src="static/img/other/titulo-seleccionar.png" width="740" height="74" alt="Titulo Seleccionar" style={{marginLeft: "190px"}}/>
                        </div>
                        <div className="subheader">Estas son las partidas que están a punto de comenzar. Elige la que quieras, sólo faltas tú.</div>
                        {/* <!-- Listado de partidas{ --> */}
                        <div className="destacados">
                            <ul id="list-games"> </ul>
                        </div>
                        {/* <!-- BTN REFRESCAR{ --> */}
                        <a href="/#" id="btn-buscador">
                            <button className="verTodas">Ver todas las partidas disponibles</button>
                        </a>
                    </div>
                </section>
                {/* <!-- 1.3. Buscador de Partidas MULTI{ --> */}
                <section id="buscador" className="subsection bg-white"  style={{display: "none"}}>
                    <div className="subsection-buscador">
                        <div className="partidasMulti">
                            <div className="secondHead">
                                <span className="text"><img src="static/img/logo/titulo-multi.png" width="537" height="76" alt="Titulo Multi"/></span>
                                <div className="filters">
                                    {/* <!-- FILTRO 8 REYES{ --> */}
                                    <div className="kings">
                                        <span className="title">Elige reyes</span>
                                        <div className="buttons">
                                            <button className="btn-reyes selected"  data-value="false">4</button>
                                            <button className="btn-reyes"           data-value="true" >8</button>
                                        </div>
                                    </div>
                                    <div className="guys">
                                        <span className="title">Elige chicos</span>
                                        <div className="buttons">
                                            <button className="btn-chicos"          data-value="1">1</button>
                                            <button className="btn-chicos selected" data-value="3">3</button>
                                            <button className="btn-chicos"          data-value="5">5</button>
                                        </div>
                                    </div>
                                    <div className="speed">
                                        <span className="title">Velocidad</span>
                                        <div className="buttons">
                                            <button className="btn-velocidad selected" data-value="false">Normal</button>
                                            <button className="btn-velocidad"          data-value="true" >Rapida</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- RESULTADOS{ --> */}
                            <div className="totalPartidas">
                                <ul id="list-games-buscador"> </ul>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- 2. INVITACIONES PENDIENTES{ --> */}
                <section id="pending" className="subsection bg-white"  style={{display: "none"}}>
                    <div className="partidasComenzar">
                        <ul id="list-pending-invitations">
                        </ul>
                    </div>
                </section>
                {/* <!-- 3. HISTORICO DE PARTIDAS{ --> */}
                <section id="historico" className="subsection bg-white"  style={{display: "none"}}>
                    {/* <!-- DETALLE RESULTADO PARTIDAs{ --> */}
                    <div className="detalleHistorico hidden popup">
                        {/* <!-- ICONO CERRAR-->
                        <a className="close btn-close-popup">
                            <img src="static/img/icon/icono_cerrar.png" width="38" height="38" alt="Icono Cerrar"/>
                        </a>
                        {/* <!-- DATOS-PARTIDA{ --> */}
                        <div className="supLine">
                            <div className="bloqleft">
                                <div className="resultado">
                                    <div className="titulo">RESULTADO</div>
                                    <div className="number match-puntuacion"></div>
                                </div>
                                <div className="datos">
                                    <div className="id"><span className="titu">Partida: </span> <span className="match-name"></span> </div>
                                    <div className="balance"><span className="titu">Balance: </span> <span className="num match-balance"></span> </div>
                                </div>
                            </div>
                            <div className="bloqright">
                                <div className="otrosdatos">
                                    <div><span className="titu">Fecha:</span><span className="num match-fecha"></span></div>
                                    <div className="middle"><span className="titu">Compañero:</span><span className="num match-partner"></span> </div>
                                    <div><span className="titu">Rivales:</span><span className="num match-opponents"></span></div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- CHICOS{ --> */}
                        <div className="midLine">  </div>
                        {/* <!-- RONDAS{ --> */}
                        <div className="listado">
                            {/* <!-- CABECERAS{ --> */}
                            <div className="head">
                                <div className="titulo gran">Grande</div>
                                <div className="titulo peq">Pequeña</div>
                                <div className="titulo pares">Pares</div>
                                <div className="titulo juego">Juego / Punto</div>
                                <div className="titulo resu">Resul.Mano</div>
                                <div className="titulo acum">Acum.Chico</div>
                            </div>
                            {/* <!-- DATOS{ --> */}
                            <div className="puntuaciones">  </div>
                        </div>
                    </div>
                    {/* <!-- LISTADO PARTIDAs{ --> */}
                    <div className="historicoPartidas">
                        {/* <!-- TITULO{ --> */}
                        <span className="title">Histórico de partidas</span>
                        {/* <!-- LISTA{ --> */}
                        <div id="historic-list" className="listado">
                            {/* <!-- CABECERAS{ --> */}
                            <div className="head">
                                <div className="titulo fecha">Fecha</div>
                                <div className="titulo comp">Compañero</div>
                                <div className="titulo rivales">Rivales</div>
                                <div className="titulo resu">Resultado</div>
                                <div className="titulo bala">Balance</div>
                            </div>
                            {/* <!-- DATOS{ --> */}
                            <div className="contenido"> </div>
                        </div>
                        {/* <!-- SI NO HAY LISTADO{ --> */}
                        <div id="empty-historic" style={{color: "#888", textAlign: "center", width: "100%", paddingTop: "200px", display: "none"}} >
                            <span>Aquí podrás ver los resultados de las partidas que juegues.</span>
                        </div>
                    </div>
                </section>
            </section>
        </div>
    );
}

export default GameSection;
