import React from 'react';
import useScript from '../../hooks/useScript';

import './ChangePassword.scss';

const ChangePassword = () => {
    // <!-- 3rd Party LIBs -->
    LoadScripts();
    return (
        <div className="bg-tapete">
            <section className="changePassForm recuperar">
                <div className="logotipo">MasterMus</div>
                <div className="boxShadow">
                    <div className="bloq">
                        <span>RECUPERAR CONTRASEÑA</span>
                    </div>
                    <div className="bloq">
                        <form method="POST">
                            <div className="textInput">
                                <div> <input id="pass1" type="password" placeholder="Introducir nueva contraseña" />  </div>
                                <div> <input id="pass2" type="password" placeholder="Confirmar nueva contraseña"  />  </div>
                            </div>
                        </form>
                    </div>
                    <div className="bloq">
                        <a className="enter" href="#"  id="btn-change-pass">Crear nueva contraseña</a>
                    </div>
                </div>
                </section>
        </div>
    );
}

export default ChangePassword;

function LoadScripts() {
    useScript('static/js/libs/jQuery/jquery.min.js');

    // <!-- CONFIG -->
    useScript('static/js/globals.js');
    // <script type="text/javascript" th:attr="src=${env}" />
    useScript('static/js/config/config-LOCAL.js');
    useScript('static/js/logger.js');

    // <!-- WEBSOCKETS -->
    useScript('static/js/libs/websockets/sockjs.js');
    useScript('static/js/libs/websockets/stomp.js');
    useScript('static/js/api/api_sockets.js');

    useScript('static/js/api/api_commons.js');
    useScript('static/js/api/api_login.js');
    //useScript('static/js/facebook.js');
    useScript('static/js/passRecovery.js');
}

