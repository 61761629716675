import React from 'react';
import useScript from '../../hooks/useScript';

import './RegisterSuccess.scss';

const RegisterSuccess = () => {
    LoadScripts();
    return (
        <div className="bg-tapete">
            <section className="centered-section">
                {/* <!-- LOGO --> */}
                <div className="logotipo"/>
                <div className="boxShadow text-centered">
                    <h1>¡ENHORABUENA!</h1>
                    <img src="static/img/icon/thumbs-up.png" width="230" height="250" alt="Thumbs Up"/>
                    <h2>¡Ya puedes jugar al mus!</h2>
                    <a className="bt-home"  href="/">Comienza a jugar</a>
                </div>
            </section>
        </div>
    );
}

export default RegisterSuccess;

function LoadScripts() {
    // <!-- 3rd Party LIBs -->
    useScript('static/js/libs/jQuery/jquery.min.js');

    // <!-- CONFIG -->
    useScript('static/js/globals.js');
    useScript('static/js/config/config-LOCAL.js');

    // <!-- WEBSOCKETS -->
    useScript('static/js/api/api_commons.js');
}