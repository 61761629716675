import React from 'react';

const HomeSection = () => {
    return (
        <section id="home" className="section content home">
            {/* <!-- BTN - Jugar Ahora --> */}
            <div className="left">
                <a href="/#" className="playnow btn-menu" data-link="game">PlayNow</a>
            </div>
            <div className="right">
                <a href="/#" className="playranking btn-menu"     data-link="ranking">MasterMus Ranking</a>
                <a href="/#" className="playtournaments btn-menu" data-link="tournament">Ultimos torneos</a>
            </div>
        </section>
    );
}

export default HomeSection;
